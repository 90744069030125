<template>
<v-app :style="colorVapp">
   <onebox_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_toolbar>
    <v-content>
        <!-- <v-card class="elevation-0"> -->
            <!-- <v-divider></v-divider> -->
            <v-overlay :value="processloader" absolute :color="color.BG" dark>
            <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
            <br />
            <br />
            <span :style="colorProgress">&nbsp; &nbsp; loading</span>
            </v-overlay>
            <v-list nav :color="color.BG">
            <v-list-item class="text-left">
                <v-list-item-avatar class="text-center">
                <v-avatar :color="color.theme" size="35">
                    <v-icon dark size="20">mdi-file-table-box-multiple</v-icon>
                </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                <v-list-item-title class="pa-1">
                    <span
                    :style="headerPage" 
                    >&nbsp;{{$t('admin.log')}}</span>
                </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            </v-list>
                    
                    <!-- <v-card class="elevation-0"> -->
            <!-- <v-card class="pa-0"> -->
              <v-container id="thiscontainer_personal_log" fluid>
                <v-expansion-panels v-model="panel" multiple outlined>
                  <v-expansion-panel class="pa-1">
                    <v-expansion-panel-header>
                      <span :style="headerPage">{{$t('adminlog.fine')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-1">
                      <v-form>
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอกชื่อไฟล์ -->
                                
                                <!-- ช่องเลือกประเภทไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="selectdata"
                                    :items="$t('default') === 'en' ? key_data_eng : key_data"
                                    item-value="key"
                                    item-text="name"
                                    :label="$t('adminlog.log_information')"
                                    prepend-icon="mdi-file-table"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    @change="getdataprocess()"
                                  ></v-select>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="selectprocess"
                                    :items="dataprocessname"
                                    item-value="process_id"
                                    :item-text="$t('default') === 'th' ? 'process_name' : 'process_name_eng' "
                                    label=""
                                    prepend-icon="mdi-file-table"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    :disabled="selectdata === ''"
                                  ></v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdateupload"
                                    v-model="modalFromdateupload"
                                    :return-value.sync="fromdateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        :rules="dateRules"
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdateupload()"
                                      :color="color.theme"
                                      :max="date_now"
                                      :min="date_back"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdateupload = false"
                                      >{{$t('myinboxPage.conditionOptional.closefromdateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodateupload"
                                    v-model="modalTodateupload"
                                    :return-value.sync="todateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :disabled="!fromdateupload"
                                        clearable
                                        readonly
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true"
                                        v-on="on"
                                        :rules="dateRules"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodateupload.save(todateupload)"
                                      :max="date_now"
                                      :min="fromdateupload"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodateupload = false"
                                      >{{$t('myinboxPage.conditionOptional.closetodateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ค้นหาไฟล์/โฟลเดอร์ -->
                                <v-flex xs12 sm12 md12 lg12>
                                 <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="logfilename"
                                    :label="$t('adminlog.file_folder_name')"
                                    prepend-icon="mdi-file"
                                    :color="color.theme"
                                  ></v-text-field>
                                 </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-flex xs12 lg12 class="text-center">
                  <br />
                <v-btn
                  class="ma-1 white--text"
                  :color="color.theme"
                  @click="get_personal_log_search()"
                >
                  <v-icon left>search</v-icon>
                  &nbsp;
                  {{ $t('myinboxPage.search')}}
                </v-btn>
                </v-flex>
            <v-layout row wrap class="pl-3 pr-3 pb-2" >
                    <v-flex lg2 xs2 :color="color.theme" class="pt-1">
                      <v-select
                        :color="color.theme"
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                        :item-color="color.theme"
                        @change="clickpagination_page1()"
                      >
                        <template v-slot:item="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex> 
                    </v-layout>
              <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="pageCount > 0">
                      <v-pagination
                        :total-visible="10"
                        v-model="page"
                        :length="pageCount || 0"
                        :color="color.theme"
                        @input="clickpagination()"
                      ></v-pagination>
                    </v-flex>
                  </v-layout>
                <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center pt-10">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <!-- <v-layout row wrap class="pl-3 pr-3" >
                    <v-flex lg2 xs12 :color="color.theme" class="pt-1">
                      <v-select
                        :color="color.theme"
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                        :item-color="color.theme"
                        @change="clickpagination_page1()"
                      >
                        <template v-slot:item="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex> 
                    </v-layout> -->
                    <!-- Phonee -->
                        <div v-if="loaddataprogresstable === true ">
                            <v-flex lg12 class="ma-2 ml-4 mr-4" >
                                <div class="text-center">
                                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                                </div>
                            </v-flex>
                        </div>
                <div v-else>
                    <div v-if="resolutionScreen <= 500">
                      <v-list two-line class="mb-6 pb-6 elevation-0" v-if="this.countdata !== 0" >
                        <v-list-item
                        v-for="item in dataprocesslog"
                        :key="item.title"
                        style="cursor: pointer;"
                        >
                        <v-list-item-content>
                        <v-list-item-title v-if="item.name !==''" v-text="$t('adminlog.file_folder_name') +' : '+ item.name"></v-list-item-title>
                        <v-list-item-title v-else style="font-size:12px;color:gray;" v-text="$t('adminlog.no_name')"></v-list-item-title>
                        <v-list-item-subtitle v-if="$t('default') === 'th'" v-text="item.process_name"></v-list-item-subtitle>
                        <v-list-item-subtitle v-else v-text="item.process_name_eng"></v-list-item-subtitle>
                        <v-list-item-subtitle v-text="formatdatetime(item.process_dtm)"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn class="elevation-0" fab small @click="opendetaillog = true , detaildata = item"><v-icon >mdi-information</v-icon></v-btn>
                        </v-list-item-action>
                        </v-list-item>
                      </v-list>
                       <v-list v-else class="mb-6 pb-6 elevation-0">
                     <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> 
                    </div>
                     <!-- Labtop -->
                     <div v-else>  
                      <v-data-table
                        :headers="($t('default') === 'en')? headerEN:headerTH"  
                        :items="dataprocesslog"               
                        class="elevation-0" 
                        :no-data-text="$t('tablefile.empty')"  
                        :page.sync="page"
                        :items-per-page="size"              
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                      <template  v-slot:[`header.type`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <!-- <template v-if="$t('default') === 'th'"  v-slot:[`header.name_th`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-else  v-slot:[`header.name_en`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template> -->
                      <template  v-slot:[`header.name`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.process_name`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.process_dtm`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.detail`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>

                       <template v-slot:item="props">
                        <tr>
                          <!-- <td width="7%" class="text-center"  ><v-icon>mdi-account</v-icon></td>
                          <td width="25%"  v-if="$t('default') === 'th'">{{ props.item.name_th}}</td>
                          <td width="25%"  v-else>{{ props.item.name_en}}</td> -->
                          <td width="25%" v-if="props.item.name !==''">{{props.item.name | subStr}}</td>
                          <td width="25%" v-else style="font-size:12px;color:gray;">{{$t('adminlog.no_name')}}</td>
                          <td width="25%" v-if="$t('default') === 'th'" >{{ props.item.process_name}}</td>
                          <td width="25%" v-else>{{ props.item.process_name_eng}}</td>
                          <td width="25%" class="text-center" >{{formatdatetime(props.item.process_dtm)}}</td>
                          <td width="25%" class="text-center"><v-btn class="elevation-0" fab small @click="opendetaillog = true , detaildata = props.item"><v-icon >mdi-information</v-icon></v-btn></td>
                        </tr>
                       </template>
                      </v-data-table>
                     </div>
                      <br>
                    <!-- <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination> -->
                    <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="pageCount > 0">
                      <v-pagination
                        :total-visible="10"
                        v-model="page"
                        :length="pageCount || 0"
                        :color="color.theme"
                        @input="clickpagination()"
                      ></v-pagination>
                    </v-flex>
                  </v-layout>
                    </div>
                    </v-flex>
                </v-layout>
                </v-container>
                <detaillog
                  :show="opendetaillog"  
                  :detaildata="detaildata"
                  @closedetaillog="opendetaillog=false"
                ></detaillog>
                 <downloadlog
                  :show="opendownloadlog"  
                  :process_type_id="data_process.process_type_id"
                  :process_id="selectprocess"
                  :selectdata="selectdata"
                  :selectprocess="selectprocessname"
                  @close="opendownloadlog=false"
                ></downloadlog>
    </v-content>
</v-app>
</template>

<script>
import { saveAs } from "file-saver";
import format from "date-fns/format";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import onebox_toolbar from "../components/layout/layout-toolbar-new";
import InfiniteLoading from "vue-infinite-loading";
const downloadlog = () => import("../components/optional/dialog-downloadlog");
const detaillog = () => import("../components/optional/dialog-detaillog");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
    components: {
        onebox_toolbar,
        detaillog,
        downloadlog,
        InfiniteLoading,
        },
 data: function() {
    return {
        a:false,
        panel:false,
        fromdateupload: "",
        modalFromdateupload: false,
        todateupload: "",
        modalTodateupload: false,
        date_now_noformat: "",
        date_back: "",
        date_now:"",
        check_filename:true,
        rootfile:[],
        logfilename:"",
        loaddataprogresstable:false,
        countdata:0,
        loaddataprogress:true,
        opendetaillog:false,
        opendownloadlog:false,
        selectdata:"",
        selectprocess:"",
        detaildata:{},
        dataprocess:[],
        data_process:[],
        bufferprocess:{},
        dataprocessname:[],
        selectprocessname:"",
        key_data:[],
        key_data_eng:[
          {
            name:"File",
            key:"จัดการไฟล์",
          },
          {
            name:"Folder",
            key:"จัดการโฟลเดอร์",
          },
          {
            name:"Share File , Share Folder",
            key:"แชร์ไฟล์, แชร์โฟลเดอร์",
          },
        ],
        dataprocesslog:[],
        size: 20,
        offset:0,
        page: 1,
        x: 0,
        y: 0,
        listsize: [10,20, 50, 100],
        headerTH:[
      // {
      //     text: "#",
      //     align: "center",
      //     value: "type",
      //     width: "7%",
      //     sortable: false,
      //   },
      // {
      //   text: "adminlog.name",
      //   align: "",
      //   value: "name_th",
      //   width: "25%",
      //   sortable: false
      // },
      {
        text: "adminlog.file_folder_name",
        align: "",
        value: "name",
        width: "30%",
        sortable: false
      },
      {
        text: "adminlog.process_name",
        align: "",
        value: "process_name",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.process_dtm",
        align: "center",
        value: "process_dtm",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.detail",
        align: "center",
        value: "detail",
        width: "8%",
        sortable: false
      }
      ],
      headerEN:[
      //    {
      //     text: "#",
      //     align: "center",
      //     value: "type",
      //     width: "7%",
      //     sortable: false,
      //   },
      // {
      //   text: "adminlog.name",
      //   align: "",
      //   value: "name_en",
      //   width: "25%",
      //   sortable: false
      // },
      {
        text: "adminlog.file_folder_name",
        align: "",
        value: "name",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.process_name",
        align: "",
        value: "process_name",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.process_dtm",
        align: "center",
        value: "process_dtm",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.detail",
        align: "center",
        value: "detail",
        width: "8%",
        sortable: false
      }],
      dateRules: [
        v => !!v || 'Date is required',
      ],
        }
    },
computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.countdata;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedData() {
      console.log("paginatedData()");
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.dataprocesslog.slice(start, start + this.size);
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    disableByPackage() {      
      return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
    },
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  methods:{
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = format(new Date(), "yyyy-MM-dd");
    },
    clickpagination(){
      console.log("clickpagination()");
      this.offset = (this.page - 1) * this.size;
      console.log(this.offset);
      console.log(this.page);
      console.log(this.size);
      this.get_personal_log_search();
    },
     clickpagination_page1(){
      this.page = 1;
      console.log("clickpagination()");
      this.offset = (this.page - 1) * this.size;
      console.log(this.offset);
      console.log(this.page);
      console.log(this.size);
      this.get_personal_log_search();
    },
    formatdatetime(_datetime) {
      // console.log(this.rootfile);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
    async getprocessname(){
      this.selectprocess={};
      var payload = {
         account_id_admin: this.dataAccountId
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/get_process_name_citizen",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(response => {
          console.log("get",response);
            if (response.data.status === "OK") {
              console.log("get1",response.data.key_data);
              this.key_data = response.data.key_data;
              this.bufferprocess = response.data.data
              console.log("bufferprocess",this.bufferprocess);
            }else{
              this.loaddataprogresstable = false;
              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });
            }
        }).catch(error => {
          console.log(error.response);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          Toast.fire({
            icon: "error",
            title: error
          });
        });
    },
    getdataprocess(){     
      console.log("bufferprocess",this.bufferprocess);
      for(let i = 0 ; i< this.bufferprocess.length;i++){       
           for(let j = 0;j<this.bufferprocess[i].process_name.length;j++){
            if(this.bufferprocess[i].process_type === this.selectdata){
              //  console.log("if",this.bufferprocess[i].process_type_id);
              if(this.bufferprocess[i].process_type_id === "0" || this.bufferprocess[i].process_type_id === "1" || this.bufferprocess[i].process_type_id === "2"){
                  
                   this.check_filename = false
               }else{
                   this.check_filename = true
               }
            //console.log(this.bufferprocess[i]);          
            this.dataprocessname = this.bufferprocess[i]["process_name"];
            this.data_process = this.bufferprocess[i];
            this.selectprocess = "all";
            console.log(this.dataprocessname);
          }
         }
      }
     // this.getprocess();
      // console.log("selectprocess",this.selectprocess);
      // console.log("data",this.data_process);
      
    },
    async get_personal_log_search(){
      // console.log("name",this.logfilename);
      // console.log("fromdate",this.fromdate);
      // console.log("todate",this.todate);
      // console.log("type_data",this.data_process.process_type_id);
      // console.log("process",this.selectprocess);
      if(this.logfilename === "" &&
        (this.fromdate === undefined || this.fromdate === "") && 
        (this.todate === undefined || this.todate === "") && 
        (this.data_process.process_type_id === undefined || this.data_process.process_type_id === "")
        ){
          console.log("กรุณากรอกข้อมูล");
          Toast.fire({
                icon: "error",
                title: "กรุณากรอกข้อมูล",
              });
        }else{
    
      // console.log("กดค้นหา",this.data_process.process_type_id);
      for(let i=0;i<this.dataprocessname.length;i++){
        // console.log("process_id",this.dataprocessname[i]["process_id"]);
         if(this.dataprocessname[i]["process_id"] === this.selectprocess){
          //  console.log(this.dataprocessname[i]["process_id"],this.selectprocess);
            this.selectprocess = this.dataprocessname[i]["process_id"]
            // console.log("selectprocess",this.selectprocessname);
         }
      }
      this.loaddataprogress = true
      this.dataprocesslog=[];
      this.personal_log_data=[];
      this.countdata=0;
        // console.log("get personal log");
        let fromdate;
        let todate;
      if(this.fromdateupload !== "" && this.todateupload !== ""){
      let str_1 = this.fromdateupload.split("-");
      fromdate= str_1[0]+str_1[1]+str_1[2];
      let str_2 = this.todateupload.split("-");
      todate = str_2[0]+str_2[1]+str_2[2];
      }else{
        fromdate = "";
        todate = "";
      }
      // console.log("selectprocess",this.selectprocess);
        var payload = {
            account_id: this.dataCitizenProfile.account_id,
            user_id: this.dataCitizenProfile.user_id,
            limit : this.size,
            offset : this.offset,
            name: this.logfilename || "",
            process: this.selectprocess === "ทั้งหมด" ? "" : this.selectprocess,
            start_dtm: fromdate === undefined ? "" : fromdate,
            end_dtm: todate === undefined ? "" : todate,
            type_data: this.data_process.process_type_id === undefined || this.data_process.process_type_id === "" ? "" : this.data_process.process_type_id,
    }
    console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER + "/api/get_repoort_log_citizen",
          payload, {headers: { Authorization: auth.code },}
        ) .then(response => {
          console.log("response",response);
          if (response.data.status === "OK") {
            console.log("ookkkkkkkkkkkkk");
            this.personal_log_data = response.data.data
            this.countdata = response.data.total_all
            // console.log("personal_log_data",this.personal_log_data);
            var i;
            for(i=0; i< this.personal_log_data.length;i++){
              let data_personal ={};
              data_personal["account_id"]= this.personal_log_data[i]["account_id"]
              data_personal["data_id"]= this.personal_log_data[i]["data_id"]
              data_personal["data_type"]= this.personal_log_data[i]["data_type"]
              data_personal["name"]= this.personal_log_data[i]["name"]
              data_personal["new_folder_id"]= this.personal_log_data[i]["new_folder_id"]
              data_personal["new_foldername"]= this.personal_log_data[i]["new_foldername"]
              data_personal["new_folderstatus"]= this.personal_log_data[i]["new_folderstatus"]
              data_personal["old_folder_id"]= this.personal_log_data[i]["old_folder_id"]
              data_personal["old_foldername"]= this.personal_log_data[i]["old_foldername"]
              data_personal["old_folderstatus"]= this.personal_log_data[i]["old_folderstatus"]
              data_personal["process"]= this.personal_log_data[i]["process"]
              data_personal["process_dtm"]= this.personal_log_data[i]["process_dtm"]
              data_personal["process_log_id"]= this.personal_log_data[i]["process_log_id"]
              data_personal["process_name"]= this.personal_log_data[i]["process_name"]
              data_personal["process_name_eng"]= this.personal_log_data[i]["process_name_eng"]
              data_personal["user_id"]= this.personal_log_data[i]["user_id"]
              data_personal["process_type"] = "44";
              
              
              this.dataprocesslog.push(data_personal);
              // this.dataprocessname.push(this.personal_log_data[i]["process_name"])
              // console.log("dataprocesslog",this.dataprocesslog[i]['process_name']);

            }
            // console.log("dataprocesslog",this.dataprocesslog);
            // console.log("dataprocessname",this.dataprocessname);
            // console.log("total_all",this.countdata);
            this.loaddataprogress = false;

          }else {
              this.loaddataprogress = false;
              Toast.fire({
                icon: "error",
                title: "ไม่พบข้อมูลการใช้งาน",
              });
            }}).catch((error) => {
            console.log(error);
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
            });
            
            }
        )
        }
    },
    // async get_personal_log(){
    //   this.loaddataprogress = true
    //   this.dataprocesslog=[];
    //   this.personal_log_data=[];
    //   // this.countdata=0;
    //     console.log("get personal log");
    //   let str_1 = this.fromdateupload.split("-");
    //   let fromdate= str_1[0]+str_1[1]+str_1[2];
    //   let str_2 = this.todateupload.split("-");
    //   let todate = str_2[0]+str_2[1]+str_2[2];
    //     var payload = {
    //         account_id: this.dataCitizenProfile.account_id,
    //         user_id: this.dataCitizenProfile.user_id,
    //         limit : this.size,
    //         offset : this.offset,
    //         name: "",
    //         process: "",
    //         start_dtm: "",
    //         end_dtm: "",
    //         type_data: "",
    // }
    // console.log("payload",payload);
    //   let auth = await gbfGenerate.generateToken();
    //   this.axios
    //     .post(
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER + "/api/get_repoort_log_citizen",
    //       payload, {headers: { Authorization: auth.code },}
    //     ) .then(response => {
    //       console.log("response",response);
    //       if (response.data.status === "OK") {
    //         console.log("ookkkkkkkkkkkkk");
    //         this.personal_log_data = response.data.data
    //         this.countdata = response.data.total_all
    //         console.log("personal_log_data",this.personal_log_data);
    //         var i;
    //         for(i=0; i< this.personal_log_data.length;i++){
    //           let data_personal ={};
    //           data_personal["account_id"]= this.personal_log_data[i]["account_id"]
    //           data_personal["data_id"]= this.personal_log_data[i]["data_id"]
    //           data_personal["data_type"]= this.personal_log_data[i]["data_type"]
    //           data_personal["name"]= this.personal_log_data[i]["name"]
    //           data_personal["new_folder_id"]= this.personal_log_data[i]["new_folder_id"]
    //           data_personal["new_foldername"]= this.personal_log_data[i]["new_foldername"]
    //           data_personal["new_folderstatus"]= this.personal_log_data[i]["new_folderstatus"]
    //           data_personal["old_folder_id"]= this.personal_log_data[i]["old_folder_id"]
    //           data_personal["old_foldername"]= this.personal_log_data[i]["old_foldername"]
    //           data_personal["old_folderstatus"]= this.personal_log_data[i]["old_folderstatus"]
    //           data_personal["process"]= this.personal_log_data[i]["process"]
    //           data_personal["process_dtm"]= this.personal_log_data[i]["process_dtm"]
    //           data_personal["process_log_id"]= this.personal_log_data[i]["process_log_id"]
    //           data_personal["process_name"]= this.personal_log_data[i]["process_name"]
    //           data_personal["process_name_eng"]= this.personal_log_data[i]["process_name_eng"]
    //           data_personal["user_id"]= this.personal_log_data[i]["user_id"]
    //           data_personal["process_type"] = "44";
              
              
    //           this.dataprocesslog.push(data_personal);
    //           this.dataprocessname.push(this.personal_log_data[i]["process_name"])
    //           // this.type_data.push(this.personal_log_data[i]["data_type"])
    //           // console.log("dataprocesslog",this.dataprocesslog[i]['process_name']);

    //         }
    //         console.log("dataprocesslog",this.dataprocesslog);
    //         console.log("dataprocessname",this.dataprocessname);
    //         // console.log("data_type",this.data_type);
    //         console.log("total_all",this.countdata);
    //         this.loaddataprogress = false;

    //       }else {
    //           this.loaddataprogress = false;
    //           Toast.fire({
    //             icon: "error",
    //             title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
    //           });
    //         }}).catch((error) => {
    //         console.log(error);
    //         this.loaddataprogress = false;
    //         Toast.fire({
    //           icon: "error",
    //           title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
    //         });
            
    //         }
    //     )
    //  },
    // search_log(){
    //   if((this.logfilename === "undefinedundefined" || this.logfilename === "" )&&
    //         this.fromdateupload === "" && this.todateupload === "" && this.todateupload === "" && this.selectdata === "" && this.selectprocess === ""){
    //   // if(this.a != true){
    //         console.log("search_log_all");
    //         console.log("logfilename",this.logfilename);
    //         console.log("fromdateupload",this.fromdateupload);
    //         console.log("todateupload",this.todateupload);
    //       this.get_personal_log();
    //   }else{
    //       console.log("search_log");
    //       this.get_personal_log_search();
    //   }
    // }
  },
  mounted() {
    // this.get_personal_log();
    this.getprocessname();
    // console.log(this.opendetaillog);
    this.loaddataprogress = false;

    // this.get_personal_log();
    //  console.log("paginatedData()",paginatedData());
    //  console.log("clickpagination()",clickpagination());
    //  console.log("get()",get());
    
  },
  created() {
    //วันปัจจุบันจัด format แล้ว
    this.date_now = format(new Date(), "yyyy-MM-dd")

    //วันปัจจุบันยังไม่จัด format เอาไว้คำนวณ
    this.date_now_noformat = new Date()

    //วันที่ก่อนวันปัจจุบัน 90 วัน
    this.date_back = format(new Date(new Date().setDate(this.date_now_noformat.getDate() - 90)), "yyyy-MM-dd")
  }
  

}
</script>

<style>
#thiscontainer_personal_log {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}

.v-select__selections {
  line-height: 28px;
}
.v-list--dense .v-list-item .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list-item--dense .v-list-item__title {
line-height: 1.5rem;
}

</style>